<template>
  <!-- 查看图表 -->
  <div class="contain">
    <div>
      <el-page-header
        style="margin-bottom: 1.04167vw"
        @back="goBack"
        :content="'校责险出险/生成图'"
      >
        <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
        </template>
      </el-page-header>
      <!-- <div class="header-title">
        校责险出险/生成图
      </div> -->
    </div>
    <div id="bar" class="echarts">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <div class="top-tilte">月份-出险数量（伤害类别）对比图</div>
            <div id="monthChart" v-show="Object.keys(echartData1).length" :style="{ width: '100%', height: '19.79167vw' }"></div>
            <div class="noEachartData" v-if="!Object.keys(echartData1).length"   :style="{ width: '100%', height: '19.79167vw' }">暂无数据</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <div class="top-tilte">星期-出险数量对比图</div>
            <div
              id="weekChart"
              v-show="Object.keys(echartData2).length"
              :style="{ width: '100%', height: '19.79167vw' }"
            ></div>
            <div class="noEachartData" v-if="!Object.keys(echartData2).length"   :style="{ width: '100%', height: '19.79167vw' }">暂无数据</div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <div class="top-tilte">出险数量趋势图</div>
            <div
              id="linkChart"
              v-show="Object.keys(echartData3).length"
              :style="{ width: '100%', height: '19.79167vw' }"
            ></div>
            <div class="noEachartData" v-if="!Object.keys(echartData3).length"   :style="{ width: '100%', height: '19.79167vw' }">暂无数据</div>
          </div>
        </el-col>
        <el-col :span="12" v-if="childChart == 1">
          <div class="grid-content bg-purple">
            <div class="top-tilte">年龄段-出险数量对比图</div>
            <div
              id="generationChart"
              v-show="Object.keys(echartData4).length"
              :style="{ width: '100%', height: '19.79167vw' }"
            ></div>
            <div class="noEachartData" v-if="!Object.keys(echartData4).length"   :style="{ width: '100%', height: '19.79167vw' }">暂无数据</div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// 引入基本模板
// let echarts = require("echarts/lib/echarts");
// // 引入柱状图组件
// require("echarts/lib/chart/bar");
// // 引入提示框和title组件
// require("echarts/lib/component/tooltip");
// require("echarts/lib/component/title");
// import { debounce } from "@/utils/brCharts.js";

export default {
  data() {
    return {
      chart: null,
      params: {
        branchId:"",
        type: "",
        orgId: "",
        parkId: "",
        generation: "",
        injuryType: "",
        week: "",
        accidentLocation: "",
        link: "",
        isClosed: "",
        beginAmount: "",
        endAmount: "",
        beginTime: "",
        endTime: "",
      },
      childChart: 0,
      echartData1: {
        name: [],
      },
      echartData2: {
        name: [],
      },
      echartData3: {
        name: [],
      },
      echartData4: {
        name: [],
      },
    };
  },

  created() {
    window.addEventListener("resize", () => {
      let monthChart = this.$echarts.init(
        document.getElementById("monthChart")
      );
      monthChart.resize();

      let weekChart = this.$echarts.init(document.getElementById("weekChart"));
      weekChart.resize();

      let linkChart = this.$echarts.init(document.getElementById("linkChart"));
      linkChart.resize();

      let generationChart = this.$echarts.init(
        document.getElementById("generationChart")
      );
      generationChart.resize();
    });
  },

  //页面初始化
  mounted() {
    let queryinfo = this.$route.query;
    // console.log(queryinfo, "生成图表生成图表");
    this.params.branchId = queryinfo.branchId;
    this.params.type = queryinfo.type;
    this.params.orgId = queryinfo.orgId;
    this.params.parkId = queryinfo.parkId;
    this.params.generation = queryinfo.generation;
    this.params.injuryType = queryinfo.injuryType;
    this.params.week = queryinfo.week;
    this.params.accidentLocation = queryinfo.accidentLocation;
    this.params.link = queryinfo.link;
    this.params.isClosed = queryinfo.isClosed;
    this.params.beginAmount = queryinfo.beginAmount;
    this.params.endAmount = queryinfo.endAmount;
    this.params.beginTime = queryinfo.beginTime;
    this.params.endTime = queryinfo.endTime;
    this.childChart = queryinfo.childChart;

    // this.resizeHandler = debounce(() => {
    //   if (this.chart) {
    //     this.chart.resize();
    //   }
    // }, 100);
    // window.addEventListener("resize", this.resizeHandler);
    this.getChildLink();
  },

  methods: {
    //返回上一页
    goBack() {
      this.$router.go(-1);
    },

    //图表数据
    getChildLink() {
      this.$api.getChildLink(this.params).then((res) => {
        if (res.data.code == 0) {
          let monthData = res.data.data.monthData; //月趋势
          let weekData = res.data.data.weekData; //星期趋势
          let linkData = res.data.data.linkData; //出险趋势
          let generationData = res.data.data.generationData; //年龄段
          this.echartData1 = monthData;
          this.echartData2 = weekData;
          this.echartData3 = linkData;
          this.echartData4 = generationData;

          this.myChartInit(monthData, weekData, linkData, generationData);
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    myChartInit(monthData, weekData, linkData, generationData) {
      let monthChart = this.$echarts.init(
        document.getElementById("monthChart")
      );
      let weekChart = this.$echarts.init(document.getElementById("weekChart"));
      let linkChart = this.$echarts.init(document.getElementById("linkChart"));

      if (this.childChart == 1) {
        let generationChart = this.$echarts.init(
          document.getElementById("generationChart")
        );

        // 绘制图表
        var generationChartOption = {
          color: ["#004DA0"],
          // title: {
          //   text: "年龄段-出险数量对比图",
          //   left: 20,
          //   textStyle: {
          //     color: "#3D3E4F", // 主标题文字的颜色。
          //     fontSize: 16, // 字体大小
          //     fontWeight: 400,
          //   },
          // },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "30",
            right: "30",
            bottom: "28",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: generationData.xList,
              axisLabel: {
                color: "#A8A6B9",
              },
              /*改变x轴颜色*/
              axisLine: {
                lineStyle: {
                  color: "#EEEFF5",
                  width: 1, //这里是为了突出显示加上的
                },
              },
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              minInterval: 1,
              axisLabel: {
                color: "#A8A6B9",
              },
              // 分割线
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#EEEFF5",
                  // type: "dashed", //虚线
                  width: 1,
                },
              },
            },
          ],
          series: [
            {
              name: "出险数量",
              type: "bar",
              barWidth: "20",
              data: generationData.yList,
              itemStyle: {
                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [4, 4, 0, 0],
                },
              },
            },
          ],
        };
        generationChart.setOption(generationChartOption);
      }

      // 绘制月份-出险数量（伤害类别）对比图图表
      let seriesList = []
      for (let index = 0; index < monthData.typeList.length; index++) {
        let str = 'list'+index
        let obj = {
            name: monthData.typeList[index],
            type: "bar",
            barWidth: "20",
            data: monthData[str],
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [4, 4, 0, 0],
              },
            },
          }
          seriesList.push(obj)
      }
      seriesList.push({
        name: "伤害总数量",
        type: "line",
        yAxisIndex: 1,
        data: monthData.avg,
      })
      // console.log(seriesList,'monthData')
      var monthChartOption = {
        // title: {
        //   text: "月份-出险数量（伤害类别）对比图",
        //   left: 20,
        //   textStyle: {
        //     color: "#3D3E4F", // 主标题文字的颜色。
        //     fontSize: 16, // 字体大小
        //     fontWeight: 400,
        //   },
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "28",
          containLabel: true,
        },
        toolbox: {
          feature: {
            dataView: { show: false, readOnly: false },
            magicType: { show: false, type: ["line", "bar"] },
            restore: { show: false },
            saveAsImage: { show: false },
          },
        },
        legend: {
          data: monthData.typeList,
          itemWidth: 10,
          itemHeight: 10,
          right: 24,
          icon: "circle", //'image://../asset/ico/favicon.png',//标志图形类型，默认自动选择（8种类型循环使用，不显示标志图形可设为'none'），默认循环选择类型有：'circle' | 'rectangle' | 'triangle' | 'diamond' |'emptyCircle' | 'emptyRectangle' | 'emptyTriangle' | 'emptyDiamond'另外，还支持五种更特别的标志图形'heart'（心形）、'droplet'（水滴）、'pin'（标注）、'arrow'（箭头）和'star'（五角星），这并不出现在常规的8类图形中，但无论是在系列级还是数据级上你都可以指定使用，同时，'star' + n（n>=3)可变化出N角星，如指定为'star6'则可以显示6角星
          textStyle: {
            color: "#9290B1",
            fontSize: "14px",
          },
        },
        xAxis: [
          {
            type: "category",
            data: monthData.month,
            axisLabel: {
              color: "#A8A6B9",
            },
            /*改变x轴颜色*/
            axisLine: {
              lineStyle: {
                color: "#EEEFF5",
                width: 1, //这里是为了突出显示加上的
              },
            },
            axisPointer: {
              type: "shadow",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            min: 0,
            max: 5,
            interval: 1,
            axisLabel: {
              formatter: "{value}",
              color: "#A8A6B9",
            },
            // 分割线
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEFF5",
                // type: "dashed", //虚线
                width: 1,
              },
            },
          },
          {
            type: "value",
            name: "",
            min: 0,
            max: 10,
            interval: 1,
            axisLabel: {
              formatter: "{value}",
              color: "#A8A6B9",
            },
            // 分割线
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEFF5",
                // type: "dashed", //虚线
                width: 1,
              },
            },
          },
        ],
        series: seriesList,
      };

      monthChart.setOption(monthChartOption);

      // 绘制星期-出险数量对比图图表
      var weekChartOption = {
        color: ["#004DA0"],
        // title: {
        //   text: "星期-出险数量对比图",
        //   left: 20,
        //   textStyle: {
        //     color: "#3D3E4F", // 主标题文字的颜色。
        //     fontSize: 16, // 字体大小
        //     fontWeight: 400,
        //   },
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "28",
          containLabel: true,
        },
        // xAxis: {
        //   type: "category",
        //   data: weekData.xList,
        // },
        xAxis: [
          {
            type: "category",
            data: weekData.xList,
            axisLabel: {
              color: "#A8A6B9",
            },
            /*改变x轴颜色*/
            axisLine: {
              lineStyle: {
                color: "#EEEFF5",
                width: 1, //这里是为了突出显示加上的
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              color: "#A8A6B9",
            },
            // 分割线
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEFF5",
                // type: "dashed", //虚线
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: "出险数量",
            type: "bar",
            barWidth: "40",
            data: weekData.yList,
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [4, 4, 0, 0],
              },
            },
          },
        ],
      };
      weekChart.setOption(weekChartOption);

      // 绘制出险数量趋势图图表
      var linkChartOption = {
        color: ["#004DA0"], //柱子颜色
        // title: {
        //   text: "出险数量趋势图",
        //   left: 20,
        //   textStyle: {
        //     color: "#3D3E4F", // 主标题文字的颜色。
        //     fontSize: 16, // 字体大小
        //     fontWeight: 400,
        //   },
        // },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "30",
          right: "30",
          bottom: "28",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: linkData.xList,
            axisLabel: {
              color: "#A8A6B9",
            },
            /*改变x轴颜色*/
            axisLine: {
              lineStyle: {
                color: "#EEEFF5",
                width: 1, //这里是为了突出显示加上的
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              color: "#A8A6B9",
            },
            // 分割线
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEFF5",
                // type: "dashed", //虚线
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: "出险数量",
            type: "bar",
            barWidth: "40",
            data: linkData.yList,
            itemStyle: {
              //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
              normal: {
                //柱形图圆角，初始化效果
                barBorderRadius: [4, 4, 0, 0],
              },
            },
          },
        ],
      };
      linkChart.setOption(linkChartOption);
    },
  },
};
</script>

<style scoped lang="scss">
.contain {
  padding: 34px 30px 30px 30px;
  background-color: #f4f4fc;

  /deep/.el-page-header__content {
    color: #3d3e4f;
    font-size: 22px;
    font-weight: bold;
  }

  .echarts {
    width: 100%;
    height: 100%;

    .el-row {
      // background-color: turquoise;
    }
    .el-col {
      border-radius: 4px;
      margin-bottom: 20px;
    }

    .bg-purple {
      background: #ffffff;
      padding: 30px 0 0 0;
    }

    .grid-content {
      border-radius: 4px;
      min-height: 80px;
    }

    .top-tilte {
      font-size: 18px;
      color: #3d3e4f;
      margin-left: 40px;
    }
  }

  .noEachartData {
    width: 100%;
    color: #999;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/div {
      display: none;
    }
  }
}
</style>
